/*=============================================================================
 _library.scss - sass common library

 (C) 2020 SpacetimeQ INC.
=============================================================================*/

/*---------------------------------------------------------------------------
 helper
---------------------------------------------------------------------------*/
// uses css text-shadow to draw outlines with the color and width in px
// limitations exist, use svg for better outline when magnified
@mixin textoutline($color, $p: 1px) {
  text-shadow:
    $p $p $color,
    -#{$p} -#{$p} $color,
    $p -#{$p} $color,
    -#{$p} $p $color;
}

// text on a box with given fg color over bg box color and side paddings
@mixin textonbox($fgc: white, $bgc: black, $p: 2px) {
  display: inline-block;
  color:            $fgc;
  background-color: $bgc;
  padding:          $p;
}

/*---------------------------------------------------------------------------
 Utility classes (Start with a Captial letter)
---------------------------------------------------------------------------*/
.Text-outline {
  @include textoutline(rgba(var(--stq-color-shadow),.8));
}

.Text-shadow {
  text-shadow: 1px 1px 1px rgba(var(--stq-color-shadow),.8);
}

.Text-shadow-md {
  text-shadow: 2px 2px 2px rgba(var(--stq-color-shadow),.8);
}
